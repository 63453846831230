import {httpClient} from "@/utils";

export const GetList = function(limit, page, keyword, filters = undefined) {
    return httpClient.get('queue/list', { params: { limit, page, keyword, filters } } );
}

export const UpdateEntity = function(guid, data) {
    return httpClient.put('queue/' + guid, data)
}

export const DeleteEntity = function(guid) {
    return httpClient.delete('queue/' + guid);
}