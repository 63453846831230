<template lang="pug">
	dir

		el-row.mar.t-1(:gutter="24")
			el-col(:xs="12" :sm="8")
				el-input(v-model="paginate.keyword" size="mini" placeholder="Search" v-debounce:300ms="fetchData")
		el-row.mg.b0-5()
			el-divider
			el-col(:span="24")
				el-pagination.mar.a-1.text-left(background="" 	@size-change="fetchData" @current-change="fetchData" :current-page.sync="paginate.page" :page-sizes="[10, 20, 30, 40]" :page-size.sync="paginate.limit" layout="prev, pager, next, sizes " :total="count")
		el-table(id="data-list", v-loading='busy', :data='list', element-loading-text='Loading', border='', fit='', highlight-current-row='')
			el-table-column(align='center', label="#" width='165')
				template(slot-scope='scope')
					span {{ scope.row._id }}
			el-table-column(align='center', label="Type" :width="100")
				template(slot-scope='scope')
					el-tooltip(v-if="scope.row.payload.type === 'group'" class="item" effect="dark" :content="'Transaction#: ' + scope.row.payload.transactionUid" placement="top-start")
						el-tag(type="success" ) Group&nbsp;
					el-tag(type="primary" v-if="scope.row.payload.type === 'lead'") Lead&nbsp;
			el-table-column(align='center', label="Action" :width="100")
				template(slot-scope='scope')
					el-tag(type="warning") {{scope.row.action}}&nbsp;
			el-table-column(align='center', label="Scheduled At")
				template(slot-scope='scope')
					span {{ scope.row.scheduled_at | formatDateTimeShort }}
			el-table-column(align='center', label="Retries" :width="60" )
				template(slot-scope='scope')
					span(v-bind:class="{'text-danger text-bold': scope.row.retries > 3}") {{ scope.row.retries || 0 }}
			el-table-column(align='center', label="Actions" :width="120" )
				template(slot-scope='scope')
					el-tooltip(class="item" effect="dark" content="Show last response" placement="top-start")
						font-awesome-icon.icon.alt.mg.l0-5.r-0-5.chooseable(:icon="['fa', 'list']"  @click="showLastResponse(scope.row.last_response)")
					el-tooltip(class="item" effect="dark" content="Revoke Message" placement="top-start")
						font-awesome-icon.icon.alt.mg.l0-5.r-0-5.chooseable(:icon="['fa', 'trash-alt']" @click="revokeMsg(scope.row._id)")

		el-row.mg.t0-5()
			el-col(:span="24")
				el-pagination.mar.a-1.text-left(background="" 	@size-change="fetchData" @current-change="fetchData" :current-page.sync="paginate.page" :page-sizes="[10, 20, 30, 40]" :page-size.sync="paginate.limit" layout="prev, pager, next, sizes " :total="count")


</template>

<style lang="scss">

</style>

<script>
import {GetList, UpdateEntity, DeleteEntity} from "@/api/queue";

export default {
	name: 'queue-list',
	data() {
		return {
			showLastResponseModal: false,
			content: '',
			paginate: {
				limit: 10,
				page: 1,
				keyword: ''
			},
			count: 0,
			list: [],
		}
	},
	mounted() {
		this.fetchData();
	},
	methods: {
		showLastResponse(content) {
			this.$alert(content, 'Last Response')
		},
		fetchData() {
			GetList(this.paginate.limit, this.paginate.page, this.paginate.keyword)
					.then(res => {
						this.list = res.list;
						this.count = res.count;
					});
		},
		revokeMsg(id) {
			this.$confirm('Are you sure that you want to revoke this message? This action cannot be undone!', 'Warning', {
				confirmButtonText: 'Delete',
				cancelButtonText: 'Cancel',
				type: 'warning'
			}).then(async () => {
				DeleteEntity(id)
					.then(res => {
						this.fetchData()
					})
			}).catch(() => {
				this.$notify.warning({
					title: 'Action Status',
					message: 'Revoke canceled'
				});
			});
		},
	}
}
</script>