<template lang="pug">
	#sections
		#section-1
			.widget-container
				el-row(:gutter="24")
					el-col(:xs="24")
						el-card.box-card
							.clearfix.d-flex.j-between(slot="header")
								h2.no-line-height.text-left Leads
								.extra
									el-tooltip(class="item" effect="dark" content="Show Queues" placement="top-start")
										font-awesome-icon.icon.alt.mg.l0-5.r-0-5.chooseable.text-info(:icon="['fa', 'plane']" @click="showQueues")

							el-row.mar.t-1(:gutter="24")
								el-col(:xs="24" :sm="8")
									el-input(v-model="paginate.keyword" size="mini" placeholder="Search" v-debounce:300ms="fetchData")
							el-divider
							el-row.mg.b0-5()
								el-col(:span="24")
									el-pagination.mar.a-1.text-left(background="" 	@size-change="fetchData" @current-change="fetchData" :current-page.sync="paginate.page" :page-sizes="[10, 20, 30, 40]" :page-size.sync="paginate.limit" layout="prev, pager, next, sizes " :total="count")
							el-table(id="data-list", v-loading='busy', :data='list', element-loading-text='Loading', border='', fit='', highlight-current-row='')
								el-table-column(align='center', label="#" width='185')
									template(slot-scope='scope')
										span {{ scope.row.id }}

								el-table-column(align='center', label="CID" width='140')
									template(slot-scope='scope')
										span {{ scope.row.crm_id || '-' }}

								el-table-column(align='center', label="E-Mail")
									template(slot-scope='scope')
										span {{ scope.row.email || '-' }}
								el-table-column(align='center', label="Phone")
									template(slot-scope='scope')
										span {{ scope.row.phone || '-' }}
								el-table-column(align='center', label="Username")
									template(slot-scope='scope')
										span {{ scope.row.username || '-' }}
								el-table-column(align='center', label="First Name")
									template(slot-scope='scope')
										span {{ scope.row.first_name || '-' }}

								el-table-column(align="center" label="Custom Name" :width="nameChangeInlineSelectFlag ? '150' : 'auto'")
									template(slot-scope="scope")
										span.text-info.chooseable(v-if="!scope.row.inline_edit && !scope.row.custom_name"  @click="toggleNameChange(scope.$index, scope.row.uid)") - Click to add -
										span.chooseable(v-if="!scope.row.inline_edit && scope.row.custom_name" @click="toggleNameChange(scope.$index, scope.row.uid)") {{ scope.row.custom_name }}
										.small-gap-btn-override
											el-input.w-100(v-if="scope.row.inline_edit" v-model="new_name[scope.row.id]" size="mini" )
												el-tooltip(slot="append" content="Click here to save")
														el-button(v-if="scope.row.inline_edit"  icon="el-icon-check" size="mini" @click="saveNewName(scope.$index)")

								el-table-column(align='center', label="Chat #")
									template(slot-scope='scope')
										span {{ scope.row.chat_id || '-' }}
								el-table-column(align='center', label="Registration" :width="135")
									template(slot-scope='scope')
										span {{ (scope.row.createdAt || '-') | formatDateTime }}
								el-table-column(align='center', label="Group")
									template(slot-scope='scope')
										//span {{ scope.row.group ? scope.row.group.name : '-' }}
										el-tooltip(v-for="t of scope.row.group")
											template(slot="content")
												| {{ t.expiration_date ? 'Expiration at' : 'No Expiration' }} {{ t.expiration_date | formatDate }}
											el-tag(type="primary" style="padding: 0 8px !important") {{t.name}}
								el-table-column(align='center', label="Active" :width="100")
									template(slot-scope='scope')
										el-tag(type="success" v-if="scope.row.last_question === 'finished'") Active&nbsp;
											font-awesome-icon.icon.alt( :icon="['fa', 'check']")
										el-tag(type="danger" v-else="") Inactive&nbsp;
											font-awesome-icon.icon.alt( :icon="['fa', 'times']")
								el-table-column(align='center', label="Actions" :width="100")
									template(slot-scope='scope')
										el-tooltip(class="item" effect="dark" content="Send signal" placement="top-start")
											font-awesome-icon.icon.alt.mg.l0-5.r-0-5.chooseable(:icon="['fa', 'pencil-alt']"  @click="action('signal', scope.row)")
										el-tooltip(class="item" effect="dark" content="Add To Group" placement="top-start")
											font-awesome-icon.icon.alt.mg.l0-5.r-0-5.chooseable(:icon="['fa', 'user-alt']"  @click="action('group', scope.row)")
										el-tooltip(class="item" effect="dark" content="Show messages" placement="top-start" )
											font-awesome-icon.icon.alt.mg.l0-5.r-0-5.chooseable(:icon="['fa', 'list']"  @click="action('messages', scope.row)")
										el-tooltip(class="item" effect="dark" content="Unsubscribe" placement="top-start")
											font-awesome-icon.icon.alt.mg.l0-5.r-0-5.chooseable(:icon="['fa', 'trash-alt']" @click="unsubscribe(scope.row.id)")
							el-row.mg.t0-5()
								el-col(:span="24")
									el-pagination.mar.a-1.text-left(background="" 	@size-change="fetchData" @current-change="fetchData" :current-page.sync="paginate.page" :page-sizes="[10, 20, 30, 40]" :page-size.sync="paginate.limit" layout="prev, pager, next, sizes " :total="count")

		#section-signal
			el-drawer.text-left( :visible.sync='showSendSignalDrawer', :with-header='true')
				template(slot="title")
					h1 Notify the lead with a signal
				el-form.pd.a1(:form="signal_form")
					el-row(:gutter="12")
						el-col(:span="24")
							el-form-item(label="Turn to suspended message")
								el-radio-group.text-left(v-model="signal_form.suspend_sending")
									el-radio(:label="true") On
									el-radio(:label="false") Off
							el-form-item(v-if="signal_form.suspend_sending" label="Suspended to")
								el-date-picker.w-100(:picker-options="pickerOptions" placeholder="Subscription Deadline" v-model="signal_form.suspend_sending_date" format="yyyy/MM/dd HH:mm" value-format="yyyy-MM-dd HH:mm:ss" type="datetime")
						el-col(:span="24")
							//h2 Form to send signals
							p Use this form to send signals to this lead
						el-col(:span="24")
							el-form-item(label="Content")
								el-input(type="textarea" :rows="10" v-model="signal_form.content" :maxlength="4096" show-word-limit="")
				.pd.a1
					el-button(type="primary" @click="sendNotification") Send
					el-button(type="danger" @click="action('signal', null, false)") Cancel
				//wysiwyg-editor(@content="($event) => this.signal_form.content = $event")

		#section-group
			el-drawer.text-left( :visible.sync='showAddToGroupDrawer', :with-header='true')
				template(slot="title")
					h1 Change Lead's Group
				el-form.pd.a1()
					el-row(:gutter="12")
						el-col(:span="24")
							p In case you want to move this lead to another group, choose from the list below and click on "save".
							p You must select the subscription expiration date. If you want to modify the date, unsubscribe from the group and subscribe again with a new date.
					el-row
						el-divider
					el-row
						el-col(:span="24")
							el-form-item(label="Subscribed in groups" v-if="currentEntry")
								//el-input(v-if="currentEntry" :value="currentEntry.group.name" :readonly="true")
								//pre {{currentEntry}}
								.w-100
									br
									el-tooltip(style="padding: 0 8px !important" v-if="currentEntry && currentEntry.group" content="Click on 'x' to unsubscribe" v-for="t of currentEntry.group")
										el-tag.mg.r0-5( type="primary"   closable=""  @close="unsubscribeFromGroup(currentEntry.id,t)") {{t.name}}
					el-row
						br
					el-row
						el-col(:span="24")
							el-form-item(v-if="currentEntry" label="Subscribe to Group" :error="currentEntry.group.guid === new_group ? '* Cannot move to the same group' : ''")
								el-select(v-if="currentEntry" v-model="new_group" )
									el-option(value="" label="- Select Group -", disabled="" selected="" )
									el-option(v-for="o of options.group" :value="o.v" :label="o.t" v-if="!currentEntry.group.some(e => e.guid === o.v)")

						el-col(:span="24")
							el-form-item(v-if="currentEntry" label="Subscription Until")
								el-date-picker(:picker-options="pickerOptions" placeholder="Subscription Deadline" v-model="expiration_date" format="yyyy/MM/dd" value-format="yyyy-MM-dd")
				.pd.a1
					el-button(type="primary" @click="submitAddToGroup" :disabled="currentEntry && (currentEntry.group.guid === new_group || new_group === '' || expiration_date === '') ") Subscribe
					el-button(type="danger" @click="action('group', null, false)") Cancel

		#section-messages
			el-dialog.override-width.search-dialog(v-if="currentEntry" :visible="showMessagesDialog" @close='showMessagesDialog = false')
				template(slot="title")
					h1 Lead Messages
						el-row(:gutter="24")
				el-row
					el-col(:xs="24")
						.infinite-list-wrapper(style="overflow:auto;" v-bind:class="{'half-screen':currentEntry.msg.length > 0}")
							//div.infinite-list( v-infinite-scroll="load" infinite-scroll-distance="500px")
							div(v-for="c of (currentEntry.msg)" )
								el-card.comments-container.mg.a0-5.text-left( shadow="hover" cover="" v-bind:id="c.id")
									template(slot="header")
										span <b>ID:</b>  {{c.ext_id}}, <b>Scope: </b>{{c.scope}}
									//label  <b>Topic: </b>{{c.topic}}
									p.text-left  {{ c.content }}
									.d-flex.justify-content-between
										//span
											b Created By:&nbsp;
											| {{ c.user.first_name + ' ' + c.user.last_name }}
										small <b>Timestamp:</b> {{ c.timestamp | formatDateTime }}
		#section-queues
			el-dialog.override-width.search-dialog(:visible="showQueuesModal" @close='showQueuesModal = false')
				template(slot="title")
					h1 Messages Queue
						el-row(:gutter="24")
				queue-list(v-if="showQueuesModal" )
				.pd.a1
					el-button(type="danger" @click="showQueuesModal = false") Cancel
</template>

<style lang="scss" >
	.small-gap-btn-override {
		 .el-input-group__append {
			padding-left: 0.3rem !important;
		}
	}
</style>

<script>
import {AddToGroup, ChangeGroup, ChangeName, GetList, SendMessage, Unsubscribe, UnsubscribeFromGroup} from "@/api/lead";
import wysiwygEditor from '../../components/wysiwyg';
import moment from 'moment';
import {GetOptions} from "@/api/option";
import queueList from './queue'
import QueueList from "@/views/Lead/queue";

export default {
	components: {QueueList, wysiwygEditor},
	data() {
		return {
			showQueuesModal: false,
			nameChangeInlineSelectFlag: false,
			fetchWatcher: null,
			showAddToGroupDrawer: false,
			showSendSignalDrawer: false,
			showMessagesDialog: false,
			currentLeadUid: null,
			busy: false,
			list: [],
			count: 0,
			paginate: {
				limit: 10,
				page: 1,
				keyword: ''
			},
			currentEntry: null,
			options: {
				group: []
			},
			signal_form: {
				content: '',
				suspend_sending: false,
				suspend_sending_date: ''
			},
			new_group: '',
			new_name: [],
			expiration_date: '',
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() < Date.now() - 86400000;
				}
			}
		}
	},
	created() {
		this.fetchData();
		this.getOptions();
		this.fetchWatcher = setInterval(() => this.fetchData(), 10000);
	},
	beforeDestroy() {
		clearInterval(this.fetchWatcher);
	},
	methods: {
		showQueues() {
			this.showQueuesModal = true;
		},
		getOptions() {
			GetOptions({filters: 'group'})
				.then(res => {
					this.options.group = res.group;
				})
		},
		fetchData() {
			if(this.nameChangeInlineSelectFlag) return;
			GetList(this.paginate.limit, this.paginate.page, this.paginate.keyword)
				.then(res => {
					this.list = res.list;
					this.list.forEach((e,i) => this.list[i].inline_edit = false)
					this.count = res.count;
				});
		},
		action(type, obj = null, status = true) {
			switch(type) {
				case 'signal': this.showSendSignalDrawer = status; this.currentEntry = obj; break;
				case 'group': this.showAddToGroupDrawer = status; this.currentEntry = obj; break;
				case 'messages': this.showMessagesDialog = status; this.currentEntry = obj; break;
			}
		},
		sendNotification() {
			let loader = this.$loading.show();
			SendMessage(this.currentEntry.id, this.signal_form.content, { suspend_sending: this.signal_form.suspend_sending, suspend_sending_date: this.signal_form.suspend_sending_date })
				.then(res => {
					console.log(res);
					if(!res) return this.$notify.error({title: 'API Error', message: 'Sending failed, please, try again'});
					// this.$notify.success({title: 'API Response', message: 'The message was successfully sent'});
					this.signal_form = { content: '', suspend_sending_date: '', suspend_sending: false};
					this.showSendSignalDrawer = false;
					this.fetchData();
					loader.hide();
				})
				.catch(e => {
					console.error(e);
					loader.hide();
				});
		},
		submitAddToGroup() {
			let loader = this.$loading.show();
			AddToGroup(this.currentEntry.id, this.new_group, this.expiration_date)
				.then(res => {
					console.log(res.data);
					if(!res._id) return this.$notify.error({title: 'API Error', message: 'Changing group failed, please, try again'});
					this.$notify.success({title: 'API Response', message: 'Lead was moved to "' + this.new_group + '" successfully'});
					this.signal_form.content = '';
					this.showAddToGroupDrawer = false;
					this.fetchData();
					this.new_group = '';
					this.expiration_date = '';
					loader.hide();
				}).catch(e => {
				console.error(e);
				loader.hide();
			})
		},
		unsubscribeFromGroup(uid,group) {
			const that = this;
			this.$confirm('Are you sure that you want to unsubscribe from this group?', 'Warning', {
				confirmButtonText: 'Unsubscribe',
				cancelButtonText: 'Cancel',
				type: 'warning'
			}).then(async () => {
				try {
					let loader = this.$loading.show();
					let result = await UnsubscribeFromGroup(uid, group.guid);
					this.currentEntry.group = this.currentEntry.group.filter(e => e.guid !== group.guid)
					this.fetchData();
					this.$notify.success({
						title: 'Action Status',
						message: 'Unsubscribed'
					});
					loader.hide();
				} catch (e) {
					loader.hide();
					that.$notify.warning({
						title: 'Action Status',
						message: 'Unsubscribe failed. Error: ' + e.message
					});
				}

			}).catch(() => {
				this.$notify.warning({
					title: 'Action Status',
					message: 'Unsubscribe canceled'
				});
			});
		},
		unsubscribe(uid) {
			const that = this;
			this.$confirm('Are you sure that you want to unsubscribe the lead? This action cannot be undone!', 'Warning', {
				confirmButtonText: 'Unsubscribe',
				cancelButtonText: 'Cancel',
				type: 'warning'
			}).then(async () => {
				let loader = this.$loading.show();
				try {
					let result = await Unsubscribe(uid);
					this.fetchData();
					this.$notify.success({
						title: 'Action Status',
						message: 'Unsubscribed'
					});
				} catch (e) {
					that.$notify.warning({
						title: 'Action Status',
						message: 'Unsubscribe failed. Error: ' + e.message
					});
				}
				loader.hide();
			}).catch(err => {
				this.$notify.warning({
					title: 'Action Status',
					message: 'Unsubscribe canceled'
				});
			})
		},
		toggleNameChange(idx, uid) {
			this.nameChangeInlineSelectFlag = true;
			this.list[idx].inline_edit = !this.list[idx].inline_edit;
			this.new_name[this.list[idx].id] = `${this.list[idx].custom_name || this.list[idx].username}`;
			this.new_name = {...this.new_name}
		},
		saveNewName(idx) {
			if(this.new_name[this.list[idx].id].length === 1) return this.$notify.warning({title: 'validation error', message: 'Must be empty or more then 2 characters'})
			console.log(this.list[idx].id);
			ChangeName(this.list[idx].id, this.new_name[this.list[idx].id])
				.then(res => {
					console.log(res);
					if(this._countEditToggled() === 1) this.nameChangeInlineSelectFlag = false;
					this.fetchData();
					this.list[idx].inline_edit = false;
					delete this.new_name[this.list[idx].id]
				})
		},
		_countEditToggled() {
			return this.list.filter(e => e.inline_edit).length;
		}
	}
}
</script>
