<template lang="pug">
  div
    editor(api-key="" :init="init" onFocusOut="$emit('content',$event)" @input="$emit('content',$event)")
</template>

<script lang="js">
// todo - move the key to be fetched fron the server

let init = ({
  height: 500,
  menubar: true,
  plugins: [
  		'markdown'
    // 'advlist autolink lists link image charmap print preview anchor',
    // 'searchreplace visualblocks code fullscreen',
    // 'insertdatetime media table paste code help wordcount'
  ],
  toolbar:
      'undo redo | formatselect | bold italic backcolor | \
      \
      bullist numlist outdent indent | removeformat | help'
})

  import Editor from '@tinymce/tinymce-vue';
  export default {
  	computed: {
  		tinyMceKey() {
  			return process.env.VUE_APP_TINYMCE_API_KEY
			}
		},
    components: {
      'editor': Editor
    },
    data() {
      return {
        init
      }
    }
  }
</script>
